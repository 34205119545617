/* eslint-disable @next/next/no-img-element */
import React from "react";
import dynamic from "next/dynamic";
import {
  Typography,
  Button,
  Stack,
  Switch,
  Tooltip,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { motion, useAnimate } from "framer-motion";
import { useMediaQuery } from "@mui/material";

const MdDialog = dynamic(() => import("./MdDialog"), { ssr: false });

const ConsentBanner = ({
  consent,
  setConsent,
  consentDialogOpen,
  setConsentDialogOpen,
  consentDate,
  setConsentDate,
  requireConsentInteraction,
  consentValid,
}) => {
  const [policyDialogOpen, setPolicyDialogOpen] = React.useState(false);
  const [customizeConsent, setCustomizeConsent] = React.useState(false);
  const [customConsent, setCustomConsent] = React.useState(
    consentDate
      ? consent
      : {
          analytics_storage: "granted",
          ad_storage: "granted",
        }
  );

  const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [mainDiv, animateMainDiv] = useAnimate();
  const [customizerDiv, animateCustomizerDiv] = useAnimate();
  const animation = {
    main: {
      show: {
        opacity: 1,
        y: 0,
        display: "flex",
      },
      hide: {
        opacity: 0,
        y: 100,
        transitionEnd: {
          display: "none",
        },
      },
    },
    // customizerOLD: {
    //   show: {
    //     times: [0, 0.5, 1],
    //     opacity: [0, 0, 1],
    //     height: ["0px", customizerDivHeight, customizerDivHeight],
    //     transition: { duration: 2.5 },
    //   },
    //   hide: {
    //     times: [0, 0.5, 1],
    //     opacity: [1, 0, 0],
    //     height: [customizerDivHeight, customizerDivHeight, "0px"],
    //     transition: { duration: 2.5 },
    //     duration: 2.5,
    //   },
    // },
    customizer: {
      show: {
        display: "flex",
        opacity: 1,
        height: "auto", //customizerDivHeight,
      },
      hide: {
        opacity: 0,
        height: 0,
        transitionEnd: {
          display: "none",
        },
      },
    },
  };

  React.useEffect(() => {
    if (consentDialogOpen) {
      animateMainDiv(mainDiv.current, animation.main.show, {
        type: "spring",
      });
    } else {
      animateMainDiv(mainDiv.current, animation.main.hide, {
        type: "spring",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentDialogOpen]);

  React.useEffect(() => {
    if (customizeConsent) {
      animateCustomizerDiv(customizerDiv.current, animation.customizer.show, {
        duration: 0.3,
      });
    } else {
      animateCustomizerDiv(customizerDiv.current, animation.customizer.hide, {
        duration: 0.3,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customizeConsent]);

  return (
    <motion.div
      ref={mainDiv}
      className={`fixed bottom-0 left-0 right-0 m-auto min-h-[200px] w-full max-w-[600px] z-[1000] rounded-xl bg-amber-400 shadow-lg justify-center items-center sm:p-3 overflow-y-scroll flex-col max-h-[90vh] box-border opacity-0 sm:bottom-6 p-2`}
      initial={animation.main.hide}
      // animate="show"
      // transition={{ duration: 2 }}
    >
      {((consentDate && consentValid) ||
        (!consentDate && !requireConsentInteraction)) && (
        <IconButton
          aria-label="close"
          onClick={() => {
            setConsentDialogOpen(false);
            setCustomizeConsent(false);
          }}
          variant="contained"
          color="secondary"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <div className="flex flex-row text-center w-full">
        <div className="w-auto h-full text-center pt-3 sm:p-5">
          <img
            src="/img/cookie.svg"
            alt="Cookie"
            style={{
              width: isXs ? "60px" : "120px",
              height: isXs ? "60px" : "120px",
            }}
          />
        </div>
        <div className="flex flex-col align-center justify-items-center h-full p-2 sm:p-3 w-auto">
          <Typography
            variant={"h4"}
            color={"black"}
            textAlign={"left"}
            gutterBottom
          >
            We use cookies
          </Typography>
          <div
            className={`flex flex-row justify-between items-center ${
              !isXs || !customizeConsent ? "" : "h-0"
            } transition-all overflow-hidden duration-1000`}
          >
            <Typography textAlign={"left"} color={"black"}>
              We use cookies and other tracking technologies to improve your
              browsing experience on our website, to show you personalized
              content and targeted ads, to analyze our website traffic, and to
              understand where our visitors are coming from.
            </Typography>
          </div>
        </div>
      </div>
      <motion.div
        ref={customizerDiv}
        className="flex-col w-full p-1 overflow-y-scroll h-0 opacity-0 hidden"
        initial={animation.customizer.hide}
      >
        <div className="bg-yellow-200 p-2 my-2 rounded">
          <div className="flex flex-row justify-between items-center">
            <Typography variant="h6" component="p" color={"black"}>
              Strictly Necessary
            </Typography>
            <Tooltip title="Always Enabled">
              <div>
                <Switch checked disabled />
              </div>
            </Tooltip>
          </div>
          <div className="flex flex-row">
            <Typography variant="caption" color={"black"}>
              These cookies are crucial for delivering the services offered
              through our website and allowing you to utilize specific features.
              If these cookies are not enabled, certain services on our website
              may not be accessible.
            </Typography>
          </div>
        </div>
        <div className="bg-yellow-200 p-2 my-2 rounded">
          <div className="flex flex-row justify-between items-center">
            <Typography variant="h6" component="p" color={"black"}>
              Analytics
            </Typography>
            <Switch
              checked={customConsent.analytics_storage === "granted"}
              onChange={() => {
                setCustomConsent({
                  ...customConsent,
                  analytics_storage:
                    customConsent.analytics_storage === "granted"
                      ? "denied"
                      : "granted",
                });
              }}
            />
          </div>
          <div className="flex flex-row">
            <Typography variant="caption" color={"black"}>
              {
                "These cookies are employed to gather data for analyzing website traffic and visitor interactions. For instance, they might monitor factors like your duration on the site or the pages you view, aiding us in enhancing the website experience. The data collected by these tracking and performance cookies is anonymous and doesn't identify individual visitors."
              }
            </Typography>
          </div>
        </div>
        <div className="bg-yellow-200 p-2 my-2 rounded">
          <div className="flex flex-row justify-between items-center">
            <Typography variant="h6" component="p" color={"black"}>
              Advertising
            </Typography>
            <Switch
              checked={customConsent.ad_storage === "granted"}
              onChange={() => {
                setCustomConsent({
                  ...customConsent,
                  ad_storage:
                    customConsent.ad_storage === "granted"
                      ? "denied"
                      : "granted",
                });
              }}
            />
          </div>
          <div className="flex flex-row">
            <Typography variant="caption" color={"black"}>
              {
                "These cookies are utilized to display personalized ads tailored to your browsing preferences. These cookies, provided by our content and advertising partners, might blend data gathered from our site with information they've independently amassed from your web browser's interactions across their network of sites. If you opt to delete or deactivate these targeting or advertising cookies, you may still encounter ads, though they might not align with your interests."
              }
            </Typography>
          </div>
        </div>
      </motion.div>
      <div className="grid grid-flow-col gap-1 sm:gap-3 w-full pt-2">
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            setConsent({
              analytics_storage: "granted",
              ad_storage: "granted",
            });
            setConsentDate(new Date());
            setConsentDialogOpen(false);
          }}
        >
          Accept All
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (!customizeConsent) setCustomizeConsent(true);
            else if (customizeConsent) {
              setCustomizeConsent(false);
              setConsent(customConsent);
              setConsentDate(new Date());
              setConsentDialogOpen(false);
            }
          }}
        >
          {customizeConsent ? "Accept Selected" : "Customize"}
        </Button>
        <Button
          variant="contained"
          color="info"
          onClick={() => setPolicyDialogOpen(true)}
        >
          Learn More
        </Button>
      </div>
      {policyDialogOpen && (
        <MdDialog
          mdFileName={"cookie-policy.md"}
          openMdDialog={policyDialogOpen}
          setOpenMdDialog={setPolicyDialogOpen}
        />
      )}
    </motion.div>
  );
};

export default ConsentBanner;
